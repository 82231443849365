.page-c{
    margin-top: 150px;
    margin-bottom: 100px;
}
.page-c h1{
    transition: all 400ms;
}
.page-c p{
    margin-top: 40px;
    color: #444;
    font-size: 25px;
    font-weight: lighter;
}
.contact{
    height: 550px;
}
.contact,
.form{
    width: 70%;
    margin: 0 auto;
}
.form{
    margin-bottom: 100px;
}
.form h2{
    margin-bottom: 10px;
    font-size: 40px; 
}
.form textarea,
.form input{
    width: 100%;
    border: none;
    border-bottom: 1px solid #444;
    margin-top: 5px;
    line-height: 45px;
    transition: all 220ms;
}
.form textarea:focus,
.form input[type="text"]:focus,
.form input[type="tel"]:focus,
.form input[type="email"]:focus{
    outline: none;
    border: 1px solid #FF8C00;
    box-shadow: 0 0 5px #FF8C00;
    line-height: 50px;
}
.form textarea:hover,
.form input[type="text"]:hover,
.form input[type="tel"]:hover,
.form input[type="email"]:hover{
    outline: none;
    border: 1px solid #FFD700;
    box-shadow: 0 0 5px #FFD700;
}

.form input[type="submit"]{
    border: none;
    font-size: 18px;
    padding: 5px;
    margin-top: 20px;
    background-color: #FFD700;
    border-radius: 5px;
    transition: all 300ms;
}
.form input[type="submit"]:hover{
    background-color:#FF8C00;
}
.form input[type="submit"]:active{
    background-color: #50C878;
}
.form label{
    width: 70%;
    padding: 5px;
}
.form select{
    width: 30%;
    padding: 5px;
    border: 2.5px solid #000000;
}
.form select:hover{
    border-color: #FFD700;
}
.form select:focus{
    
    border-color:#FF8C00 ;
}
.select-div{
    width: 100%;
    margin-bottom: 2%;
    margin-top: 2%;
}



/* estilos de tablet */
@media only screen and (max-width: 850px){
    .contact{
        height: auto;
        margin-bottom: 70px;
    }
    .page-c .form h2,
    .page-c h1{
        font-size: 60px;
    }
    .h1-hover-mobile2{
        color: #FF8C00;
        font-size: 60px;
    }
}
/* Estilos para escritorio del li */
@media only screen and (min-width: 1024px){
    .page-c .contact:hover h1{
        color: #FF8C00;
    }
    
    .page-c .h{
        font-size: 60px;
        color: #000000;
    }
    
}
/* Estilos para telefonos */
@media only screen and (max-width: 600px){
   
    .page .page-c,
    .page{
        margin-top: 250px;
        margin-bottom: 200px;
    }
    .page-c .form h2,
    .page-c h1{
        font-size: 40px;
    }
      
}
.page-e{
    margin-top: 200px;
    margin-bottom: 200px;
}
.error{
    width: 100%;
}
.error button{
    font-size: 18px;
    padding: 0 20px;
    background-color: #FFD700;
    border-radius: 5px;
    border: none;
    margin-left: 39%;
    margin-top: 40px;
    transition: all 300ms;
}
.error button:hover{
    background-color:#FF8C00 ;
}
.error button:active{
    background-color: #50C878;
}
.error a{
    color: black;
    text-decoration: none;
}
/* estilos tablet */
@media only screen and (max-width: 768px) {
    .error button{
        margin-left: 30%;
    }
}
/* estilos telefono */
@media only screen and (max-width: 600px){
    .error button{
        margin-left: 0;
        width: 100%;
        height: auto;
    }
}
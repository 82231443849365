.page .anunciox1{
    width: 70%;
    align-items: center;
    text-align: center;
    margin-top: 155px;
    margin-left: 14%;
    margin-bottom: 250px;
    min-height: auto;
    padding: 40px;
    transition: all 300ms;
    border-radius: 35px;
}
.page .div-hover{
    box-shadow: 1px 1px 20px black;

}

.page .anunciox1 h1{
    font-size: 60px;
}
.page .anunciox1 p{
    font-size: 25px;
    margin-top: 40px;
    line-height: 40px;
    color: #444;
    font-weight: lighter;
}
.page .anunciox1 button{
    font-size: 18px;
    padding: 0 20px;
    background-color: #FFD700;
    border-radius: 5px;
    border: none;
    margin-left: 10px;
    margin-right: 10px;
    transition: all 300ms;
}
.page .anunciox1 button:hover{
    background-color:#FF8C00 ;
}
.page .anunciox1 button:active{
    background-color: #50C878;
}
.page .anunciox1 a button {
    color: #000000;
    text-decoration: none;
}
.page .anunciox4 {
    margin-bottom: 180px;
}
.page .anunciox4 p{
    font-size: 25px;
    color: #444;
    margin-top: 20px;
    padding: 2px;
    font-weight: lighter;
}
.page .anunciox4 h2{
    font-size: 70px;
    text-decoration: underline;
    text-decoration-color: #FFD700; /* Color para el subrayado */
    text-decoration-thickness: 2px; /* Grosor de 2px para el subrayado */
    text-underline-offset: 12px; /* Desplaza el subrayado 3px hacia abajo */
}
.page .anunciox4 ul{
    list-style: none;
    padding: 0;
    display: flex;
    margin-top: 130px;
    height: auto;
}
.page .anunciox4 ul li{
    padding: 0 10px 10px 10px;
    transition: all 400ms;
}
.page .anunciox4 ul li h3{
    font-size: 30px;
    transition: all 400ms;
}
.page .anunciox4 div{
    margin-top: 25px;
}

/* efecto hover telefono */
    
.hover-efecto-x4-li,
.hover-efecto-x4-li h3 {
font-size: 32px;
color: #FF8C00;
}

.hover-efecto-x1-div{
    box-shadow: 1px 1px 20px #FFD700 ;
}

/* poner lo de abajo en uan media de tablet a mobil */
/* Estilos para tabletas */
@media only screen and (max-width: 850px) {
    .page .anunciox4 ul{
        height: auto;
        display: block;
    }
  }
/* Estilos para telefonos */
@media only screen and (max-width: 600px){
    .page .anunciox1{
        width: 100%;
        margin-left: 0;
        box-shadow: none;
    }
    .page .anunciox4 h2{
        font-size: 40px;
    }
    .page .anunciox1 h1{
        font-size: 40px;
        text-decoration: underline;
        text-decoration-color: #FFD700;
        text-decoration-thickness: 2px;
        text-underline-offset: 12px;
    }
    .page .anunciox1:hover{
        box-shadow: none;
    }
    .page .anunciox1 p{
        line-height: 50px;

    }
    .page .anunciox1 p button{
        line-height: 40px;
    }
    .page .anunciox4 ul{
        display: block;
        height: auto;
        
    }
    .page .anunciox4 ul li{
        margin-top: 30px;
    }

    .list{
        overflow: hidden;
    }
    
    
}
/* Estilos para escritorio del li */
@media only screen and (min-width: 1024px){
    .page .anunciox4 ul .li-a:hover h3,
    .page .anunciox4 ul .li-a:hover {
       font-size: 32px;
       color: #FF8C00;
    }
    .page .anunciox1:hover{
        box-shadow: 1px 1px 20px #FFD700 ;
    }
}